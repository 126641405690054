body {
  padding-bottom: 30px;
  overflow: scroll;
}

/* Leaflet */
.leaflet-container {
  height: 97vh;
  width: 100%;
  margin: 0 auto;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

.ui.vertical.segment.xvd-footer {
  background-image: url("../medias/citta-logo-footer-white-line-1px.png");
  background-repeat: repeat-x;
  background-position-y: 50px;
  height: 51px;
}

.xvd-home-crop {
  width: 400px;
  height: 30px;
  overflow: hidden;
}

.toastmessages {
  position: fixed;
  top: 15px;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, 0);
}

/* Bug menu recouvert par la carte leaflet */
.ui.menu .menu {
  z-index: 1000;
}

.reset-some-element {
  all: initial;
  * {
    all: unset;
  }
}
